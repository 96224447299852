import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";

function Vocalamp(){
    
        return(
            <div> 
                <ScrollToTopButton/>
                <ProjectHeroPanel
                    image="vocalamp-banner.png"
                    label1="Key contributions"
                    label2="Team"
                    label3="Duaration"
                    text1="Distillation of literature for connected devices technology.                    "
                    text2="Form factor design of smart lamp in Fusion 360, and rapid prototyping of mobile app using Figma."
                    text3="Ambient sensor interfacing on RPi4 using Python."
                    text4="Hardware and Software Designer/Developer"
                    text5="Product Designer"
                    text6="User Researcher"
                    text7="3 Months"
                    text8=""
                    text9=""
                ></ProjectHeroPanel>

                {/* Overview Section of the project */}
                <div class="block relative mt-12 ">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            OVERVIEW
                        </h6>
                        <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                            Vocalamp
                        </h1>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Multi-feature smart lamp and Android application for smart learning</h3>
                        <h4 class="text-xl md:text-2xl font-semibold mb-4   text-gray-600 dark:text-gray-400 ">The lamp improves the reading experience of print story books for young children by providing various vocabulary learning techniques with the use of IoT.</h4>
                        <p class="text-lg  md:text-base  text-gray-600  dark:text-gray-400 ">Print books are proven to be crucial to reading comprehension for young children as compared to digital books. The child can point the finger at the word and can learn about the new words they encounter.</p>
                    </div>
                </div>
                
                {/* Mobile Prototype and Image */}
                <div class="block relative">
                    <div class="container">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <img src={require(`../Images/vocalamp/mobile-compressed-gif.gif`)}  alt="Mobile Prototype" class="rounded-xl scale-75"/>
                            </div>
                            <div class="p-8 flex flex-col justify-center">
                                <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                        VOCALAMP APPLICATION FOR PARENTS
                                </h6>
                                <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Lamp controls and Vocabulary</h3>
                                <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">Seamlessly track the progress of your child while you are away.</p>
                                <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">Discuss their learnings of the day through new words they had learnt.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                

                {/* Timeline */}
                <div class="block relative  mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            RESEARCH TIMELINE
                        </h6>
                        <img src={require(`../Images/vocalamp/web-timeline.png`)}  alt="" class="rounded-xl"/>   
                    </div>
                </div>

                {/* Solution */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            SOLUTION
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Lamp design and features</h3>
                        <div class="flex flex-col rounded-xl p-4 bg-stone-300/80 dark:bg-gray-800" >
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">The Lamp helps children learn new words while reading physical books by using a camera to process and recognize words through gesture detection.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">The word is parsed and feedback is given to the child as pronunciation and meaning through a speaker, utilizing dictionary and speech APIs.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">The Lamp also provides visual feedback through LED lights, creating immersive lighting conditions.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">There is also a mobile app available for parents to track the words their child learns throughout the day, as well as a snapshot of the ideal lighting conditions for the study desk.</li>
                        </ul>      
                        </div>               
                        <div class="grid grid-cols-2 gap-4 mt-8">
                            <img 
                                src={require(`../Images/vocalamp/lamp-design.jpg`)}  
                                alt="" 
                                class="rounded-xl w-full h-96 object-cover"
                            />   
                            <img 
                                src={require(`../Images/vocalamp/web-working-diagram.png`)}  
                                alt="" 
                                class="rounded-xl w-full h-96 object-cover"
                            />   
                        </div>
                    </div>
                </div>

                {/* Hardware */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            HARDWARE
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Custom PCB board, interfacing with Raspberry Pi 4/ TSL2591 High Dynamic Range Digital Light Sensor</h3>
                        <img src={require(`../Images/vocalamp/image-grid.png`)}  alt="Collection of prototype images" class="rounded-xl mt-8"/>
                    </div>
                </div>

                {/* Reflections */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <div class="flex flex-col rounded-xl p-4 bg-stone-300/80 dark:bg-gray-800">
                            <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                 REFLECTION
                            </h6>
                            <h4 class="text-xl md:text-2xl font-semibold   text-gray-600 dark:text-gray-400 ">All about IoT</h4>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Only during full-scale product development, one can understand development challenges on the hardware front and their close integration with software.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">We could achieve a full loop of a capable prototype that we tested with some kids and found them in amazement with the features.</li>
                            </ul>
                            <h4 class="text-xl md:text-2xl font-semibold   text-gray-600 dark:text-gray-400 ">What went well?</h4>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">A centralized product development strategy and a dedicated researcher kept us on the right mid-set to develop the product. </li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">We started with digital bookmarks for efficient reading moved to projection technologies and eventually constructed a smart lamp. All because of rigorous literature reviews.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Further Details */}
                <div class="block relative mt-12 mb-12">
                    <div class="container ">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                 FURTHER DETAILS
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">The lamp underwent several iterations to determine its form factor and the optimal placement of sensors. I would like to provide a full tour of this innovative IoT product.</h3>
                        <p class="text-lg md:text-base  text-gray-600  dark:text-gray-400">Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                    </div>
                </div>
            </div>
        )
    }

export default Vocalamp;