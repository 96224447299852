import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import YouTubePlayer from "../YoutubePlayer/YoutubePlayer";
import ImageSlider from "../ImageSlider";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";


const prototypeImages = [
    { src: require(`../Images/marysplace/web-midfid-proto-1.png`), alt: 'Image 1' },
    { src: require(`../Images/marysplace/web-midfid-proto-2.png`), alt: 'Image 2' },
    { src: require(`../Images/marysplace/web-midfid-proto-3.png`), alt: 'Image 3' },
];

const lowFidelityWireframes = [
    { src: require(`../Images/marysplace/web-wireframe-1.png`), alt: 'Image 1' },
    { src: require(`../Images/marysplace/web-wireframe-2.png`), alt: 'Image 2' },
];

const researchData = [
    {
      title: "Field study",
      text: "We observed field research where a family of 10 comprised of 8 children & an injured head of household were living out of an SUV.",
      image: require(`../Images/marysplace/web-fr-car.jpg`)
    },
    {
      title: "SME semi- structured interview",
      text: "Interviewed outreach team head on timelines, clients, demography, scheduling, etc. Managed interview & follow-up questions, aggregated questions.",
      image: require(`../Images/marysplace/web-fr-allen-1.jpg`)
    },
    {
      title: "Focus group",
      text: "Focus groups were challenging due to the correctness of answers, sensitive info, and fear of judgment but managed well. Asked key questions to get resource categories & pain points.",
      image: require(`../Images/marysplace/web-fr-allen-2.jpg`)
    },
    {
      title: "Verifying our current understanding",
      text: "Specialists suggesting on the user flow diagram.",
      image: require(`../Images/marysplace/web-fr-allen-3.jpg`)
    }
  ];

function Marysplace(){

    return(
        <div>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="marys-place-banner.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Ehtnographic studies and design sprints"
                text2="Wireframing and usability testing of hypotheses and delivery of mock-ups."
                text3="Implementing design for iOS app - Honeycomb"
                text4="Product Manager"
                text5="2 Developers"
                text6="UX Designer"
                text7="Research Jul - Sep - 2022"
                text8="Design and Development Oct - Dec - 2022"
                text9=""
            >

            </ProjectHeroPanel>
            
            {/* Marys Place Project Overview  */}
            <div class="block relative mt-12 mb-12">
                 <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                        OVERVIEW
                    </h6>
                    <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                    Honeycomb
                    </h1>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">iOS app designed for Mary's Place field staff to efficiently manage resources.</h3>
                    <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">Mary's Place, Seattle is an non-profit that provides resources and shelter to vulnerable communities in King County to help them in their journey out of homelessness. As of January 2020, Washington state had an estimated 22,923 residents experiencing homelessness on any given day. To address emerging needs for Mary's Place, Starbucks sponsored the research and development efforts as CSR efforts to curb homelessness.</p>
                </div>
            </div>
        
            {/* Mobile Screens  */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <div class="grid grid-cols-3 gap-4">
                        <div class="flex flex-col gap-4 mb-8">
                            <img src={require(`../Images/marysplace/mobile-Home-screen.png`)}  alt="" class="rounded-xl"/>   
                            <div class="flex flex-col items-start relative rounded-xl p-6 h-36 bg-stone-300/80 dark:bg-gray-800">
                                <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Dashboard provides easy monitoring of the marysplace performance.</p>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/marysplace/mobile-list-view.png`)}  alt="" class="rounded-xl"/>   
                            <div class="flex flex-col items-start relative rounded-xl p-6 h-36 bg-stone-300/80 dark:bg-gray-800">
                                <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Marysplace details allows easy manipulation of various status and stages during deal progress.</p>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/marysplace/mobile-resource-details-page.png`)}  alt="" class="rounded-xl"/>   
                            <div class="flex flex-col items-start relative rounded-xl p-6 h-36 bg-stone-300/80 dark:bg-gray-800">
                                <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Offering details helps you negotiate easily with clients and change in data.</p>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4">
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/marysplace/mobile-maps-view.png`)}  alt="" class="rounded-xl"/>   
                            <div class="flex flex-col items-start relative rounded-xl p-6 h-36 bg-stone-300/80 dark:bg-gray-800">
                                <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Maps enable users to quickly locate nearby resources, both near their current location and during outreach.</p>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/marysplace/mobile-maps-view.png`)}  alt="" class="rounded-xl"/>   
                            <div class="flex flex-col items-start relative rounded-xl p-6 h-36 bg-stone-300/80 dark:bg-gray-800">
                                <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Clients details where users create profiles, use a scratch pad to document family needs, add resources & share/print from the app.</p>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/marysplace/mobile-add-resources-family.png`)}  alt="" class="rounded-xl"/>   
                            <div class="flex flex-col items-start relative rounded-xl p-6 h-36 bg-stone-300/80 dark:bg-gray-800">
                                <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Resource onboarding is a form to onboard resources with granular details, a primary method to create enriched resources.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Timeline Image */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            RESEARCH AND EXECUTION TIMELINE
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Divided research and execution into following phases</h3>
                    <br></br> 
                    <img src={require(`../Images/marysplace/web-timeline.png`)}  alt="" class="rounded-xl"/>                      
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 mt-8 text-gray-700 dark:text-gray-200 ">Structured research from reports, field studies and interviews</h3>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                    Stakeholder matrix created from annual reports data
                    </h4>
                    <img src={require(`../Images/marysplace/web-stakeholder-matrix.png`)}  alt="" class="rounded-xl"/>                      
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-400 ">
                    Current process
                    </h4>
                    <img src={require(`../Images/marysplace/web-user-flow-1.png`)}  alt="" class="rounded-xl"/>                      
                    
                    {/* Unordered List  */}
                    <div class="flex flex-col rounded-xl p-8 mt-8  bg-stone-300/80 dark:bg-gray-800">
                        <div>
                            <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-200 ">
                            Information gaps & research questions for primary research
                            </h4>
                            <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Thes questions were generated as the team's collective understanding of Mary's Place functioning and history. I choose the appropriate research tool moving forward to answer these questions.</p>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Incomplete understanding of user flow.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Intake vs Outreach roles in the diversion process</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">What resources are families seeking from Mary's Place? </li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">How do Outreach Specialists document/share/manage resources?</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">What happens after outreach and housing assessment?</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">How eligilibility is determined?</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">How are families prioritized?</li>
                            </ul>
                        </div>
                    </div>

                    {/* Images and Information Panel */}
                    {researchData.map((item,index)=> (
                        <div key={index} class="grid grid-cols-3 gap-4 mt-8">
                        <div class="flex rounded-xl bg-stone-300/80 dark:bg-gray-800">
                                <div class="flex flex-col justify-between m-8">
                                    <h4 class="text-xl md:text-2xl font-semibold text-gray-600 dark:text-gray-200 ">{item.title}</h4>
                                    <p class="text-lg md:text-base  text-gray-600  dark:text-gray-200">{item.text}</p>
                                </div>                          
                        </div>
                        <div class="col-span-2">
                            <img src={item.image}  alt="research-images" className="rounded-xl "/>
                        </div>
                    </div>
                    ))}
                </div>
            </div>

            {/* Triangulated Insights and analysis of research */}
            <div class="black relative mt-24 mb-12">
                <div class="container"> 
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            TRIANGULATED INSIGHTS AND ANALYSIS OF RESEARCH
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">An overview of research insights and methods.</h3>
                    <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">Research findings and definig high-level categories via affinity mapping</h4>
                    <img src={require(`../Images/marysplace/web-affinity-map.jpg`)}  alt="" class="rounded-xl"/>                      
                    <h4 class="text-xl md:text-2xl font-semibold mt-8 mb-4 text-gray-600 dark:text-gray-200 ">Redesigned the user flow diagram</h4>
                    <img src={require(`../Images/marysplace/web-user-flow-2.png`)}  alt="" class="rounded-xl"/>                      
                </div>
            </div>

            {/* Conclusion */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            CONCLUSION
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Learnings and emerging design requirements</h3>
                    <div class="grid grid-cols-3 gap-4">
                        {[
                        ['Multiple locations, services and individual status','Reflect on provider-services hierarchy.'],
                        ['Refined knowledge base in silos.','Mechanism to "favorite" resources.'],
                        ['Resources sharing through text or physical artifacts.','Direct sharing from the solution or print details.'],
                        ['Resources status is volatile.','Vetting process or regular audits on resources.'],
                        ['Frequent help offered from other organizations and MOUs.','Distinct labeling for partners'],
                        ].map(([title,description],index)=>(
                            <div class="">
                                <div class="flex flex-col justify-between rounded-xl h-48 p-8 bg-stone-300/80 dark:bg-gray-800">
                                    <p class=" text-gray-600 dark:text-gray-400" key={index}>
                                        {title}
                                    </p>
                                    <p class=" text-gray-600 dark:text-gray-400">
                                        {description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
            {/* Ideation */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            IDEATION
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Design Process</h3>
                    <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">Design Question</h4>
                    <div class="flex flex-col justify-between rounded-xl  p-8 bg-stone-300/80 dark:bg-gray-800">
                        <div>
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">How might we create a tool for the outreach specialist to manage disparate resources and assist clients throughout the outreach process?</h5>
                        </div>
                    </div>
                    <h4 class="text-xl md:text-2xl font-semibold mt-8 text-gray-600 dark:text-gray-200 ">Branstorming</h4>
                    <img src={require(`../Images/marysplace/web-brain-storming-1.jpg`)}  alt="Brainstorming sketches" class="rounded-xl"/>                      
                    <h4 class="text-xl md:text-2xl font-semibold mt-8 text-gray-600 dark:text-gray-200 ">Low-fidelity Wireframes</h4>
                    <div >
                        <ImageSlider images={lowFidelityWireframes} />
                    </div>
                    
                    {/* Slider to be inserted here */}
                    <h4 class="text-xl md:text-2xl font-semibold mt-8 text-gray-600 dark:text-gray-200 ">Usability Testing on medium-fidelity wireframes</h4>
                    <p class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">We evaluated primary tasks create, edit, browse, filters, and share of resources. I constructed the test plan, scenario and evaluation strategy. Firstly, I made sure the script was intact  and we structured post-task interviews with individual users.</p>   
                    <h5 class="text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-3">
                        Usability Session 1
                    </h5>
                    <div class="flex flex-col rounded-xl p-8   bg-stone-300/80 dark:bg-gray-800">
                        <p class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Prominent issues that lead to a 50% task success rate.</p>
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Nomenclature - Provider and Services hierarchy didin't match the mental model of users.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Maps - Issues with combining maps and resources list, users expected maps as differentiating factor.</li>
                        </ul>
                    </div>
                    <div>
                        <ImageSlider images={prototypeImages} scaleImageFactor={"scale120"}/>
                    </div>
                    
                </div>
            </div>

            {/* Final Deliverable */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            FINAL DELIVERABLES
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Application walkthrough</h3>
                    <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">User testing on software prototypes</h4>
                    <div class="flex flex-col rounded-xl p-8   bg-stone-300/80 dark:bg-gray-800">
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Created new plan for usability session 2, eliminating errors caused by cosmetic issues(e.g. favorite button) and redundant nomemclature (e.g. lists and provider-services, now called resources</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Test focused on end-to-end task accomplishments with team of 6, including previous and new testers. Task sucess rate was 100%.</li>
                        </ul>
                    </div>
                    <div class="grid grid-cols-3 gap-4 mt-8">
                        <div class="flex flex-col rounded-xl p-8   bg-stone-300/80 dark:bg-gray-800">
                            <div class="flex flex-col h-96 justify-between m-8">
                                    <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">The app walkthrough for scenario below.</h4>
                                    <p class="text-lg md:text-base  text-gray-600  dark:text-gray-400">Users are in the middle of outreach engaging with a family of 5.</p>
                                    <p class="text-lg md:text-base  text-gray-600  dark:text-gray-400">Upon arriving, the family expresses that they need help finding several resources like employment, translation, and food.</p>
                                    <p class="text-lg md:text-base  text-gray-600  dark:text-gray-400">Let’s explore how Honeycomb can help.</p>
                            </div>                          
                        </div>
                        <div class="col-span-2">
                            <YouTubePlayer videoId="NYZkuI-w8rg"/>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Reflections */}
            <div class="clock relative mt-24 mb-12">
                <div class="container">
                    <div class="flex flex-col rounded-xl p-8 mt-8 bg-stone-300/80 dark:bg-gray-800">
                        <div>
                            <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            REFLECTIONS
                            </h6>

                            <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">What could have been better?</h4>
                            <ul class="list-none p-0">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- I could have created a scalable design system for any teams that work on the project in the future. </li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- Additional visual assests to make the application appealing, explorations into empty states and other edge cases.</li>
                            </ul>
                            <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">What went well?</h4>
                            <ul class="list-none p-0">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- The correct blend of the team with prior experience in delivering products was the best mixture for this project, thus balancing discussions and delivery.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- Through research and access to the Allen family center and outreach specialists.</li>
                            </ul>
                            <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-200 ">Challenges we faced?</h4>
                            <ul class="list-none p-0">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- Some key challenges were understanding the internal language they associate while at work.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- While resources onboarding is easy, the access can be granted to anyone, but we found out later that it might compromise the resource intergrity this reducing user base.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Final Deliverable */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                        FURTHER DETAILS
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Multiple pivots, specific testing plans, research strategy, design system and more to share.</h3>
                    
                    <p class=" text-gray-600 dark:text-gray-400">Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>

        </div>
        
    )
}

export default Marysplace;