import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import "../components.css";
import { Link } from "react-router-dom";

const componentOverview = [
    { src: require(`../Images/optum/web-interaction-collection-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/web-interaction-collection-2.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/web-interaction-collection-3.png`), alt: 'Image 3' },
];

const componentGroup = [
    { src: require(`../Images/optum/text-inputs-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/multi-inputs-1.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/multi-inputs-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/toggle.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-1.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-3.png`), alt: 'Image 3' },
];

function Optum(){
    
    return(
        <div>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="optum-housecalls.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Designing Medication, Diagnosis, Review of Systems Sections in Figma"
                text2="Modularizing/Contributing to component utilization and design for Optum Design Systems - Netra"
                text3="Developer assistance in implementing components via storybook guidance"
                text4="Associate Director of Nursing, 4 Technical Project Manger"
                text5="30-35 Developer and QA resources"
                text6="Senior UX Designer, UX Designer"
                text7="HouseCalls - May 2022 - Dec 2023"
                text8=""
                text9=""
            >
            </ProjectHeroPanel>

            
            {/* ----------------------------------------------Overview Section of the project----------------------------------------- */}
            <div class="block relative mt-8 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                         OVERVIEW
                    </h6>
                    <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                    Optum - HouseCalls
                    </h1>
                    <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">
                        HouseCalls is an annual in-home clinical assessment for members of participating health plans.
                        The assessment can improve performance on key Star measures. A HouseCalls visit results in a
                        more complete and accurate identification of a member’s health conditions.
                    </p>
                    <a href="https://www.optum.com/en/business/health-plans/members/in-home-clinical-assessment.html" class=" text-indigo-600 dark:text-indigo-400 hover:underline font-medium">
                    Read about HouseCalls
                    </a>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                    Impact
                    </h4>
                    <div class="flex flex-col p-8 items-start w-full relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest  text-gray-800 dark:text-gray-200">
                            FACTSHEET
                        </h6>
                        
                            <div class="grid grid-cols-3 gap-x-8 w-full mb-8">
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">2.1M</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">86%</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">99%</h2>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Visits including 61K virtual visits</p>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Star gap closure rate</p>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Member satisfaction</p>
                            </div>
                            <div class="grid grid-cols-3 gap-x-8 w-full">
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">50</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">91%</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">95%</h2>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">States where HouseCalls<br/>is available</p>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Repeat visit<br/>acceptance rate</p>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Coding Accuracy</p>
                            </div>
                    </div>
                    <hr class="mt-8 mb-8 border-slate-800" />
                </div>
            </div>
            <div class="block relative mt-24 mb-12">
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                         HOUSECALLS INTERFACE
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">
                        Designed Medication and Review of systems 
                     </h3>
                     <img src={require(`../Images/optum/project-hero.png`)}  alt="" class="rounded-xl"/>    
                        
                </div>

                <div class="container mt-12"> 
                    {/* Unordered List  */}
                    <div class="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                            <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                    CONTEXT
                            </h6>
                            <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">HouseCalls</h3>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">HouseCalls provides in-home visits for members (45 -60 mins) and works with health plans to address undiagnosed health conditions, aiming to improve outcomes and reduce costs.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Members receive personalized attention from Advanced Practice Clinicians, including a comprehensive exam, medication review, and health screenings , all within the comfort of their own homes.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">The program offers educational materials and resources tailored to each member's healthcare needs, promoting better understanding and management of their health.</li>
                            </ul>
                        </div>
                    </div>

                    {/* My Contributions */}
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 mt-12 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                    DESIGN CONTRIBUTION
                    </h6>
                    <div className="grid grid-cols-3 gap-4">
                        
                        <div class="flex flex-col items-start relative rounded-xl p-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Design of Active/Inactive medication user flows</h5>
                        </div>
                        <div class="flex flex-col items-start relative rounded-xl p-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Interaction patterns for Review of Systems forms</h5>
                        </div>
                        <div class="flex flex-col items-start relative rounded-xl p-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Modularizing component structure with Dev teams</h5>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

            {/*------------------------------------------ Active/Inactive Medication Section--------------------------------------- */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 mt-12 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                    MEDICATION
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                         Design of Active/Inactive medication user flows
                    </h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400 ">For a comprehensive medication overview, all the data related to medication consumption must be updated for better inference and major outcomes of the APCs visit.
                    </p>
                </div>

                {/* Research */}
                <div class="container">
                    {/* Unordered List  */}
                    <div className="flex flex-col relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                        <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400">
                            Research questions for APCs Interview
                        </h4>
                        
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">What is Medication process in context of house visits?</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none my-4">What are the outliers in and data inconsistencies in current records?</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none my-4">What are other pages of application where medication data is pivotal?</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none ">Would users have a shared view of interface while visit?</li>
                        </ul>
                        </div>
                    </div>
                    {/* Unordered List  */}
                    
                    {/* Unordered List  */}
                    <div className="flex flex-col items-start relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                        <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                        Interview outcomes
                        </h4>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">While collecting medication information members might provide false data, they may be consuming over-the-counter available medication, or might be taking herbal medication.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">A recent hospitalization will require APC to gather complete information on the medication completed by the members, this can impact regular medication records.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Medication data include quantity, time, frequency, last fill data, Non-adherence to medication, Active/Inactive, and more.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Apart from collecting medication APCs are obligated to update any past pending or missing record for medication. </li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Outlier scenarios can include caregiver not present, member diagonsed with Dimentia/Alzhemiers, original bottles not available.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Outcomes and Interface Suggestions */}
                <div class="container mt-8">
                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                        Outcomes and Interaction Suggestions
                    </h4>
                                        
                    {/* Images and Information Panel */}
                    <div class="grid grid-cols-3 gap-4">
                        <div class="flex flex-col col-span-1 items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <div class="m-8">
                                
                                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400" >Wireframes</h4>
                                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">Ideated 3 versions to segrate data and presented to APCs. Key outcomes include a two step approach, first allow APC to collect information for past medication and later mark Active/Inactive medications.</p>
                                
                            </div>
                        </div>
                        <div className="flex col-span-2 flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <img src={require(`../Images/optum/web-wf-collection-1.png`)}  alt="" className="grid-image "/>
                        </div>
                    </div>
                </div>

                {/* Outcomes and Interface Suggestions */}
                
                <div class="container mt-8">
                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                        Component Overview
                    </h4>
                    {/* <div className="space-bottom-extra-large">
                        <ImageSlider images={componentOverview} />
                        
                    </div> */}
                </div>
                <ImageSwiper images={componentOverview}  />


            </div>

            {/*------------------------------------------ Active/Inactive Medication Section--------------------------------------- */}
            <div class="block relative mt-24 mb-12 ">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                                    REVIEW OF SYSTEMS
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                        Researched faster execution of Review of Systems forms.
                    </h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">The APCs have limited time allocation per member visit, this requires the system to leverage available time and onboard user data efficiently. 
                    </p>
                </div>

                {/* Research */}
                <div class="container">
                    {/* Unordered List  */}
                    <div class="flex flex-col relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                            <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400" >Research performed on Review of Systems data.</h4>
                            <ul className="list-none p-0">
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Exploration of form data that will be collected from the member. </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">What atoms of the design system can be leveraged to build a consistent and modular form? </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 ">How to future-proof the system for different data collection processes. </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {/* Outcomes and Interface Suggestions */}
                <div class="container">
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 mt-8">
                    Outcomes and Interaction Suggestions
                    </h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">Created possible states derived from Optum’s design system Netra and helped developers build and test all component instances in storybook js. This resulted in overall perfection in interaction patterns across all forms in HouseCalls.</p>
                    {/* <div className="space-bottom-extra-large">
                        <ImageSlider images={componentGroup} />
                    </div> */}
                </div>
                <ImageSwiper images={componentGroup}/>
            </div>
            
            {/* Final Deliverable */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  dark:text-indigo-500 dark:text-gray-300">
                            FURTHER DETAILS
                    </h6>
                    <br></br>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Led the integration of rapid design systems across various HouseCalls applications and pages, driving notable improvements in design and code modularity. This initiative streamlined development processes, fostering efficiency and ensuring consistent user experiences.</h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400 ">Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>
        </div>
    )
};

export default Optum;