import React from "react";
import Button from "../Button";
import "../components.css";
import Badge from "../Badge/Badge";
import { useNavigate } from "react-router-dom";

const ProjectCards = ({id,image, panelSubheading, cardHeading, cardSubheading, badgesArray, secureStatus, openModal}) => {    
    
    const navigate = useNavigate();
    const handleCardClick = (projectId) => {
        if(secureStatus){
            openModal(id);
        }else{
            navigate(`/project/${projectId}`);    
        }

        };

    
    return(
        // <div className="section">
        // Section
        <div class="block relative mt-36 mb-36"> 
            {/* Container */}
            {/* <div key={(`${cardHeading}`)} className="container grid-container" > */}
            <div key={(`${cardHeading}`)} class="container grid">
                
                {/* Layout-grid and Divide Grids in half */}
                {/* <div className="layout-grid grid-halves"> */}
                <div class="grid grid-cols-2 gap-4">
                    
                    {/* Card Panel Left */}
                    {/* <div className="panel"> */}
                    <div class="flex">
                        <img src={require(`../Images/${image}`)}  alt="" class=" rounded-xl"/>
                    </div>
                     {/* Class for Panels  */}
                    <div class="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800 ">
                        
                        {/*Class for Panel body  */}
                        <div>
                                {/* Card Details */}
                                <div class="mt-8 ml-8 mr-8 px-0 py-1 ">
                                    <h6 class="font-mono text-lg md:text-lg font-semibold mb-4 my-4 tracking-widest text-indigo-500  dark:text-indigo-500">
                                    {panelSubheading}
                                    </h6>
                                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                                    {cardHeading}
                                    </h3>
                                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                                    {cardSubheading}
                                    </h4>

                                    <div class="mt-8 flex flex-row items-start gap-2">{badgesArray.map((badge) => (
                                            <Badge label={badge} badgeColor={"bg-slate-700"}/>
                                        ))}
                                    </div>
                                </div>
                                {/* Button Details */}
                                
                                {/* <div className="button-row"> */}
                                <div className="absolute bottom-8 left-8 space-x-8 ">
                                    <Button size="lg" label="View Designs" onClick={() => handleCardClick(id)}  secure={secureStatus}/>    
                                </div>
                            
                            </div>                          
                        </div>
                    
                </div>
            </div>
        </div>
    );
};

export default ProjectCards;

